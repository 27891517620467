<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card :disabled="loading" :loading="loading">
      <v-container>
        <div class="d-flex justify-end mt-2 mx-4">
          <template v-if="dateInterval == 'custom'">
            <v-row class="mx-2" no-gutters>
              <v-col>
                <ma-date-picker v-model="dateFrom" label="From" past />
              </v-col>
              <v-col class="ml-2">
                <ma-date-picker v-model="dateTo" label="To" past />
              </v-col>
            </v-row>
          </template>
          <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  :loading="loading"
                  depressed
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>mdi-tune</v-icon>
                  Filter
                </v-btn>
              </template>

              <v-list width="140" nav dense>
                <v-list-item
                  v-for="interval in intervals"
                  :key="interval.value"
                  link
                  @click="setIntervar(interval.value)"
                >
                  <v-list-item-title>{{ interval.label }}</v-list-item-title>
                </v-list-item>
                <v-divider />

                <v-list-item link @click="dateInterval = 'custom'">
                  <v-list-item-title>Select</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="dateInterval = 'this-year'">
                  <v-list-item-title class="red--text">Clean</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <v-layout class="pa-4" column>
          <div class="mr-2 text-capitalize">
            {{ dateInterval.replace("-", " ") }}
          </div>
          <v-layout column class="text-body-1 text--secondary">
            <div>
              From
              <span class="text-caption">{{ prettyDate(query.date1) }}</span>
            </div>
            <div>
              To <span class="text-caption">{{ prettyDate(query.date2) }}</span>
            </div>
          </v-layout>
        </v-layout>
        <v-layout row class="pa-4">
          <h2 class="pl-4">New Bussiness:</h2>
          <h2>{{ " " + newBussiness }}</h2>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { PolicyStatus } from "@/utils/enums/policystatus";
import moment from "moment";
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import StatisticsStatus from "@/views/Statistics/StatisticsStatus.vue";
export default {
  name: "new-bussiness",
  components: { MaDatePicker, StatisticsStatus },
  data() {
    return {
      loading: false,

      dateInterval: "this-year",
      dateFrom: null,
      dateTo: null,
      newBussiness: 0,

      intervals: [
        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "This week", value: "this-week" },
        { label: "Last week", value: "last-week" },
        { label: "This month", value: "this-month" },
        { label: "Last month", value: "last-month" },
        { label: "This year", value: "this-year" },
        { label: "Last year", value: "last-year" },
      ],
      query: {
        date1: "",
        date2: "",
      },
    };
  },
  computed: {
    intervalToday() {
      const from = moment().startOf("day");
      const to = moment();
      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarYesterDay() {
      const from = moment().subtract(1, "days").startOf("day");
      const to = moment().subtract(1, "days").endOf("day");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisWeek() {
      const from = moment().startOf("week");
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastWeek() {
      const from = moment().subtract(1, "week").startOf("week");
      const to = moment().subtract(1, "week").endOf("week");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisMonth() {
      const from = moment().startOf("month");
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastMonth() {
      const month = moment().month() + 1;

      // Si coincide con enero retornar el mes actual
      if (month == 1) {
        return this.intervarThisMonth;
      }

      const from = moment().subtract(1, "month").startOf("month");
      const to = moment().subtract(1, "month").endOf("month");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisYear() {
      const from = moment().startOf("year");
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastYear() {
      const from = moment().subtract(1, "year").startOf("year");
      const to = moment().subtract(1, "year").endOf("year");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
  },
  watch: {
    dateFrom(val) {
      if (
        val !== null &&
        val !== this.dateTo &&
        this.dateTo !== null &&
        this.dateTo !== ""
      ) {
        this.getNewBussiness();
      }
    },
    dateTo(val) {
      if (
        val !== null &&
        val !== this.dateFrom &&
        this.dateFrom !== null &&
        this.dateFrom !== ""
      ) {
        this.getNewBussiness();
      }
    },
  },
  methods: {
    setIntervar(value) {
      this.dateInterval = value;
      this.getNewBussiness();
    },
    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    getNewBussiness() {
      let interval;
      switch (this.dateInterval) {
        case "today":
          interval = this.intervalToday;
          break;
        case "yesterday":
          interval = this.intervarYesterDay;
          break;
        case "this-week":
          interval = this.intervarThisWeek;
          break;
        case "last-week":
          interval = this.intervarLastWeek;
          break;
        case "this-month":
          interval = this.intervarThisMonth;
          break;
        case "last-month":
          interval = this.intervarLastMonth;
          break;
        case "this-year":
          interval = this.intervarThisYear;
          break;
        case "last-year":
          interval = this.intervarLastYear;
          break;
        case "custom":
          interval = {
            date1: moment(this.dateFrom).startOf("day").toISOString(),
            date2: moment(this.dateTo).endOf("day").toISOString(),
          };
          break;
      }
      this.query = interval;
      this.loading = true;
      getAPI
        .post("/patient/filterPatients", {
          range: { limit: 100, offset: 0 },
          createdInterval: {
            date1: interval.date1,
            date2: interval.date2,
          },
        })
        .then((res) => {
          this.newBussiness = res.data.count;
          this.loading = false;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
  mounted() {
    this.getNewBussiness();
  },
};
</script>
<style lang=""></style>
