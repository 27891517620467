<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card :disabled="loading" :loading="loading">
      <v-container>
        <template>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="statistics"
            :footer-props="footerProps"
            class="elevation-1"
          >
            <template v-slot:[`item.bronze`]="{ item }">
              <div class="font-weight-bold" v-bind:style="{ color: '#CD7F32' }">
                {{ item.bronze }}
              </div>
            </template>
            <template v-slot:[`item.silver`]="{ item }">
              <div class="font-weight-bold" v-bind:style="{ color: '#C0C0C0' }">
                {{ item.silver }}
              </div>
            </template>
            <template v-slot:[`item.gold`]="{ item }">
              <div class="font-weight-bold" v-bind:style="{ color: '#EDC43D' }">
                {{ item.gold }}
              </div>
            </template>
            <template v-slot:[`item.policies`]="{ item }">
              <div class="cursor-pointer" @click="showDetails(item)">
                {{ item.policies }}
              </div>
            </template>
          </v-data-table>
          <v-dialog
            v-model="dialog"
            scrollable
            fullscreen
            persistent
            :overlay="true"
            transition="dialog-transition"
          >
            <template v-if="dialog && currentItem">
              <policies-view
                :typeData="'company'"
                :idSearch="currentItem"
                :nameItem="nameItem"
                @closeDialog="closeDialog"
              />
            </template>
          </v-dialog>
        </template>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import PoliciesView from "@/views/Statistics/PoliciesView.vue";
export default {
  components: { PoliciesView },
  name: "statistic",
  data() {
    return {
      statistics: [],
      loading: false,
      dialog: false,
      currentItem: null,
      nameItem: null,
      footerProps: {
        "items-per-page-options": [100, 500, 1000],
      },
      headers: [
        {
          text: "Business Type",
          align: "start",
          sortable: false,
          value: "company",
        },
        {
          text: "Policies",
          align: "center",
          sortable: false,
          value: "policies",
        },
        {
          text: "Active Policies",
          align: "center",
          sortable: false,
          value: "active_polices",
        },
        {
          text: "Members",
          align: "center",
          sortable: false,
          value: "members",
        },
        {
          text: "Bronze",
          align: "center",
          sortable: false,
          value: "bronze",
        },
        {
          text: "Silver",
          align: "center",
          sortable: false,
          value: "silver",
        },
        {
          text: "Gold",
          align: "center",
          sortable: false,
          value: "gold",
        },
      ],
    };
  },

  methods: {
    getStatistics() {
      this.loading = true;
      getAPI("/reports/policies")
        .then((res) => {
          this.statistics = res.data;
          this.loading = false;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    showDetails(item) {
      this.dialog = true;
      this.currentItem = item.id ? item.id.toString() : "total";
      this.nameItem = item.id ? item.company : "All Policies";
    },
    closeDialog() {
      this.dialog = false;
      this.currentItem = null;
      this.nameItem = null;
    },
  },
  mounted() {
    this.getStatistics();
  },
};
</script>
<style lang=""></style>
