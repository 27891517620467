<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card :disabled="loading" :loading="loading">
      <v-row no-gutters justify="end" class="px-4 py-2">
        <v-col cols="12" sm="2">
          <v-select
            :items="durations"
            v-model="duration"
            item-text="label"
            item-value="value"
            label="Duration"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2" class="px-1">
          <ma-date-picker v-model="dateFrom" label="From" past />
        </v-col>
        <!--   <v-col cols="12" sm="2">
          <ma-date-picker v-model="dateTo" label="To" past />
        </v-col>
 -->
        <v-col cols="12" sm="1">
          <template v-if="validClean">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ml-2"
                  elevation="0"
                  small
                  rounded
                  depressed
                  fab
                  v-on="on"
                  v-bind="attrs"
                  @click="_clearFilters"
                >
                  <v-icon>mdi-backup-restore</v-icon>
                </v-btn>
              </template>
              <span>Clear Date filters</span>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
      <template>
        <v-row class="pa-2" no-gutters>
          <v-col cols="12" sm="12">
            <!-- <div class="d-flex justify-end">
              <template v-if="!viewGraph">
                <v-btn color="success" text @click="configureGraph"
                  ><v-icon>mdi-chart-line</v-icon>Graph</v-btn
                >
              </template>
            </div> -->
            <div>
              <v-data-table
                :headers="headers"
                :items="statistics"
                :options.sync="options"
                :loading="loading"
                :footer-props="footerProps"
                :server-items-length="total"
                item-key="uuid"
                class="elevation-1"
              >
                <template v-slot:[`item.phone`]="{ item }">
                  <template v-if="item.phone">
                    <div class="d-flex justify-center">
                      <div
                        class="cursor-pointer"
                        @click="makeCallV(item.phone)"
                      >
                        {{ item.phone }}
                      </div>
                      <div class="mx-1">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              @click="copyPhone(item.phone)"
                              v-on="on"
                              small
                              color="info"
                              >mdi-content-copy</v-icon
                            >
                          </template>
                          <span>Copy Phone</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </template>
                  <template v-else> - </template>
                </template>
                <template v-slot:[`item.name`]="{ item }"
                  ><span class="cursor-pointer" @click="showDetails(item)">{{
                    item.name
                  }}</span></template
                >
                <template v-slot:[`item.last_name`]="{ item }"
                  ><span class="cursor-pointer" @click="showDetails(item)">{{
                    item.last_name
                  }}</span></template
                >
                <template v-slot:[`item.statusNom`]="{ item }">
                  <div
                    class="text-body-1 cursor-pointer"
                    v-bind:style="{ color: colorShow(item) }"
                    @click="showDetails(item)"
                  >
                    {{ statusShowReal(item) }}
                  </div>
                </template>
                <template v-slot:[`item.sourceNom`]="{ item }">
                  <span
                    @click="showDetails(item)"
                    v-bind:style="{ color: colorShowS(item) }"
                    class="font-weight-bold cursor-pointer"
                    >{{ item.sourceNom ? item.sourceNom.name : "-" }}</span
                  >
                </template>
              </v-data-table>
            </div>
          </v-col>
          <!-- <template v-if="viewGraph">
            <v-col cols="12" sm="12" class="px-4 pt-4 d-flex justify-center">
              <base-graphic :configC="configGraph" />
              <div class="d-flex justify-end px-2">
                <v-btn color="error" text @click="closeGraph"
                  ><v-icon>mdi-cancel</v-icon>Close</v-btn
                >
              </div>
            </v-col>
          </template> -->
        </v-row>
      </template>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import { mapActions, mapState } from "vuex";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import moment from "moment";
import BaseGraphic from "@/components/graphics/BaseGraphic.vue";
import { funtionsPhone } from "@/utils/mixins";
export default {
  name: "statistic-last-action",
  mixins: [funtionsPhone],
  components: {
    MaDatePicker,
    BaseGraphic,
  },
  data() {
    return {
      dateTo: "",
      dateFrom: "",
      duration: 30,
      durations: [
        { label: "30 Seconds", value: 30 },
        { label: "45 Seconds", value: 45 },
        { label: "1 Minute", value: 60 },
        { label: "5 Minutes", value: 300 },
        { label: "30 Minutes", value: 1800 },
        { label: "1 Hour", value: 3600 },
      ],
      statistics: [],
      loading: false,
      viewGraph: false,
      configGraph: null,
      options: {},
      total: 0,
      footerProps: {
        "items-per-page-options": [15, 25, 100, 500, 1000],
      },

      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Lastname",
          align: "center",
          sortable: false,
          value: "last_name",
        },
        {
          text: "Phone",
          align: "center",
          sortable: false,
          value: "phone",
        },
        {
          text: "Source",
          align: "center",
          sortable: false,
          value: "sourceNom",
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "statusNom",
        },
      ],

      req: {
        /*  offset: 0,
        limit: 100, */

        interval: {
          date1: "",
          date2: "",
        },
      },
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["loadingRefer", "companies"]),
    validClean() {
      if (
        (this.dateFrom != null &&
          this.dateFrom != undefined &&
          this.dateFrom != "") ||
        (this.dateTo != null && this.dateTo != undefined && this.dateTo != "")
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    duration(val) {
      if (val == 0 || val == null || val == undefined) {
        this.duration = 1;
      }
      this.getStatistics();
    },
    options(val) {
      if (val != {}) {
        this.getStatistics();
      }
    },
    dateFrom(val) {
      if (val != null) {
        this.getStatistics();
      }
    },
    dateTo(val) {
      if (val != null && this.dateFrom != null && this.dateFrom != "") {
        this.getStatistics();
      }
    },
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actListCompaniesNom"]),

    getStatistics() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;

      this.loading = true;

      /*   body.limit = itmper;
      body.offset = (page - 1) * itmper; */
      const dur =
        this.duration == 0 ||
        this.duration == null ||
        this.duration == undefined
          ? 1
          : this.duration;
      let body = {
        limit: itmper,
        offset: (page - 1) * itmper,
        duration: Number(dur),
        fromDate: moment().toISOString(),
      };

      if (this.dateFrom != "") {
        body.fromDate = moment(this.dateFrom).startOf("day").toISOString();
      }
      getAPI
        .post("/reports/last-lead-action", body)
        .then((res) => {
          this.statistics = res.data.result;
          this.total = res.data.count;
          this.viewGraph = false;
          this.loading = false;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    _clearFilters() {
      if (this.dateFrom != "" && this.dateTo != "") {
        this.dateFrom = "";
        this.dateTo = "";
        this.getStatistics();
      } else {
        this.dateFrom = "";
        this.dateTo = "";
      }
    },

    configureGraph() {
      const labels = this.statistics.map((s) => {
        return s.name;
      });
      const leads = this.statistics.map((s) => {
        return s.leads;
      });
      const clients = this.statistics.map((s) => {
        return s.clients;
      });

      const data = {
        labels: labels,
        datasets: [
          {
            label: "Clients",
            data: clients,

            backgroundColor: ["rgba(75, 192, 192)"],

            borderRadius: 3,
            borderSkipped: false,
          },
          {
            label: "Leads",
            data: leads,

            backgroundColor: ["rgba(54, 162, 235)"],

            borderRadius: 3,
            borderSkipped: false,
          },
        ],
      };
      this.configGraph = {
        type: "bar",
        data: data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            /*  title: {
              display: true,
              text: "Chart.js Bar Chart",
            }, */
          },
        },
      };
      this.viewGraph = true;
    },
    closeGraph() {
      this.viewGraph = false;
    },

    statusShowReal(item) {
      const est = item.statusNom ? item.statusNom.name : "No Status";
      if (est == null || est == undefined) {
        return "-";
      }
      return est;
    },
    colorShow(item) {
      return item.statusNom ? item.statusNom.color : "#000";
    },

    colorShowS(item) {
      return item.sourceNom ? item.sourceNom.color : "#000";
    },
    showDetails(item) {
      this.$router.push(`/leads_details/${item.uuid}`);
    },
    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
};
</script>
<style lang=""></style>
