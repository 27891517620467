<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card :disabled="loading" :loading="loading">
      <v-container>
        <v-layout>
          <v-radio-group row v-model="filterBy">
            <v-radio
              label="DOB/ Name/ Last Name"
              value="dob_name_lastname"
            ></v-radio>
            <v-radio label="Name/ Last Name" value="name_lastname"></v-radio>
            <v-radio label="Email" value="by_email"></v-radio>
            <v-radio label="Phone" value="phone_number"></v-radio>
            <!--   <v-radio label="Policy Number" value="policy_number"></v-radio> -->
          </v-radio-group>
        </v-layout>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="duplicateLeads"
          :footer-props="footerProps"
          :options.sync="options"
          :server-items-length="total"
          class="elevation-1"
        >
          <template v-slot:[`item.phone`]="{ item }">
            <template v-if="item.phone">
              <div class="d-flex">
                <div class="cursor-pointer" @click="makeCallV(item.phone)">
                  {{ item.phone }}
                </div>
                <div class="mx-1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        @click="copyPhone(item.phone)"
                        v-on="on"
                        small
                        color="info"
                        >mdi-content-copy</v-icon
                      >
                    </template>
                    <span>Copy Phone</span>
                  </v-tooltip>
                </div>
              </div>
            </template>
            <template v-else> - </template>
          </template>
          <template v-slot:[`item.dob`]="{ item }">
            {{ getDOB(item.dob) }}
          </template>
          <template v-slot:[`item.fullname`]="{ item }">
            {{ item.fullname ? item.fullname : "" }}
          </template>
          <template v-slot:[`item.counts`]="{ item }"
            >{{
              item.policy_number_count
                ? item.policy_number_count
                : item.dob_count
                ? item.dob_count
                : item.fullname_count
            }}
          </template>

          <template v-slot:[`item.actions`]="{ item }"
            ><template v-if="item.relations || item.leads">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="success"
                    icon
                    @click="showRelations(item)"
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </template>
                <span>Show Details</span>
              </v-tooltip>
            </template>
          </template>
        </v-data-table>
        <v-dialog
          v-model="relationShow.dialog"
          persistent
          :overlay="false"
          max-width="900px"
          transition="dialog-transition"
        >
          <v-card>
            <v-toolbar class="primary" tag="div" flat dark tile>
              <div class="text-h6">{{ "Relationships" }}</div>
              <v-spacer></v-spacer>
              <v-btn color="white" icon @click="closeShow"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-toolbar>

            <v-card-text class="mt-3">
              <v-tabs v-model="tabsD">
                <v-tab href="#leads">Leads</v-tab>
                <v-tab href="#relations">Relations</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabsD">
                <v-tab-item value="leads">
                  <template v-if="relationShow.item && relationShow.item.leads">
                    <v-data-table
                      :headers="headersD"
                      :items="relationShow.item.leads"
                      single-expand
                      show-expand
                      item-key="index"
                      :expanded.sync="expandedL"
                      :items-per-page="10"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.email`]="{ item }">
                        {{ item.email ? item.email : "-" }}
                      </template>

                      <template v-slot:[`item.phone`]="{ item }">
                        <template v-if="item.phone">
                          <div class="d-flex justify-center">
                            <div
                              class="cursor-pointer"
                              @click="makeCallV(item.phone)"
                            >
                              <span> {{ item.phone }}</span>
                            </div>
                            <div class="mx-1">
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    @click="copyPhone(item.phone)"
                                    v-on="on"
                                    small
                                    color="info"
                                    >mdi-content-copy</v-icon
                                  >
                                </template>
                                <span>Copy Phone</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </template>
                        <template v-else> - </template>
                      </template>
                      <template v-slot:[`item.name`]="{ item }">
                        <span>
                          {{ item.name ? item.name : "" }}
                          {{ item.last_name ? item.last_name : "" }}</span
                        >
                      </template>

                      <template v-slot:[`item.dob`]="{ item }">
                        <span> {{ getDOB(item.dob) }}</span>
                      </template>

                      <template v-slot:expanded-item="{ headers }">
                        <td :colspan="headers.length">
                          <template v-if="loadingLead">
                            <div class="d-flex justify-center pa-3">
                              <v-progress-circular
                                :size="50"
                                :width="5"
                                color="primary"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </template>
                          <template v-if="leadDetails && !loadingLead">
                            <v-container>
                              <v-row no-gutters>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    ID
                                  </div>
                                  <div class="text-body-1">
                                    {{ leadDetails.id ? leadDetails.id : "-" }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Status
                                  </div>
                                  <div class="text-body-1">
                                    <span
                                      class="font-weight-bold cursor-pointer"
                                      v-bind:style="{ color: colorShow }"
                                      >{{ statusShow }}</span
                                    >
                                  </div>
                                </v-col>

                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Gender
                                  </div>
                                  <div class="text-body-1">
                                    {{ leadDetails.isMale ? "Male" : "Female" }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Date of Birth
                                  </div>
                                  <div class="text-body-1">
                                    {{ getDate(leadDetails.birthDate) }}
                                  </div>
                                </v-col>
                              </v-row>
                              <v-divider class="my-2"></v-divider>
                              <v-row no-gutters>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Full name
                                  </div>
                                  <div class="text-body-1">
                                    {{
                                      leadDetails.name ? leadDetails.name : "-"
                                    }}
                                    {{
                                      leadDetails.last_name
                                        ? leadDetails.last_name
                                        : ""
                                    }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Email
                                  </div>
                                  <div class="text-body-1">
                                    {{
                                      leadDetails.email
                                        ? leadDetails.email
                                        : "-"
                                    }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Phone
                                  </div>
                                  <div class="text-body-1">
                                    <template v-if="leadDetails.phone">
                                      <div class="d-flex">
                                        <div
                                          class="cursor-pointer"
                                          @click="makeCallV(leadDetails.phone)"
                                        >
                                          {{ leadDetails.phone }}
                                        </div>
                                        <div class="mx-1">
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                              <v-icon
                                                @click="
                                                  copyPhone(leadDetails.phone)
                                                "
                                                v-on="on"
                                                small
                                                color="info"
                                                >mdi-content-copy</v-icon
                                              >
                                            </template>
                                            <span>Copy Phone</span>
                                          </v-tooltip>
                                        </div>
                                      </div>
                                    </template>
                                    <template v-else> - </template>
                                  </div>
                                </v-col>
                              </v-row>
                              <v-divider class="my-2"></v-divider>
                              <v-row no-gutters>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Source
                                  </div>
                                  <div class="text-body-1">
                                    <span
                                      v-bind:style="{ color: colorShowS }"
                                      class="font-weight-bold"
                                      >{{
                                        leadDetails.sourceNom
                                          ? leadDetails.sourceNom.name
                                          : "-"
                                      }}</span
                                    >
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Create at
                                  </div>
                                  <div class="text-body-1">
                                    {{ getDOBR(leadDetails.createAt) }}
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </template>
                        </td>
                      </template>
                    </v-data-table></template
                  >
                </v-tab-item>
                <v-tab-item value="relations">
                  <template
                    v-if="relationShow.item && relationShow.item.relations"
                  >
                    <v-data-table
                      :headers="headersR"
                      :items="relationShow.item.relations"
                      single-expand
                      item-key="index"
                      show-expand
                      :expanded.sync="expandedR"
                      :items-per-page="10"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.leadcreateAt`]="{ item }">
                        {{ getDOBR(item.leadcreateAt) }}
                      </template>
                      <template v-slot:[`item.relation`]="{ item }">
                        {{ item.relation ? item.relation.employee : "" }}
                      </template>

                      <template v-slot:[`item.actions`]="{ item }"
                        ><template v-if="item">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                color="info"
                                icon
                                @click="gotoLead(item.leadUuid)"
                                ><v-icon>mdi-account</v-icon></v-btn
                              >
                            </template>
                            <span>Show Lead Details</span>
                          </v-tooltip>
                        </template>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <template v-if="item.relation">
                            <v-row>
                              <v-col
                                cols="12"
                                sm="4"
                                class="d-flex flex-column"
                              >
                                <label for="">Employee:</label>
                                <span>{{ item.relation.employee }}</span>
                              </v-col>
                            </v-row>
                          </template>
                          <template v-else>
                            <div>
                              <div class="text-center">
                                {{ "Does not have relationships" }}
                              </div>
                            </div>
                          </template>
                        </td>
                      </template>
                    </v-data-table>
                  </template>
                  <template v-else>
                    <div class="text-center">
                      {{ "Does not have relationships" }}
                    </div>
                  </template>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-layout>
          <base-graphic />
        </v-layout> </v-container
    ></v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import { LeadStatus } from "@/models/Lead";
import moment from "moment";
import BaseGraphic from "@/components/graphics/BaseGraphic.vue";
import { funtionsPhone } from "@/utils/mixins";
export default {
  components: { BaseGraphic },
  name: "duplicate-leads-statistics",
  mixins: [funtionsPhone],
  data() {
    return {
      duplicateLeads: [],
      filterBy: "dob_name_lastname",
      loading: false,
      footerProps: {
        "items-per-page-options": [15, 25, 50, 500],
      },
      options: {},
      expandedR: [],
      expandedL: [],
      leadDetails: null,
      loadingLead: false,
      total: 0,
      relationShow: {
        dialog: false,
        item: null,
      },
      tabsD: "duplicate",
      headersD: [
        {
          text: "Fullname",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Phone",
          align: "center",
          sortable: false,
          value: "phone",
        },
        {
          text: "Email",
          align: "center",
          sortable: false,
          value: "email",
        },
        {
          text: "DOB",
          align: "center",
          sortable: false,
          value: "dob",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "data-table-expand",
        },

        // { text: "", value: "data-table-expand" },
      ],
      headersR: [
        {
          text: "Fullname",
          align: "start",
          sortable: false,
          value: "leadFullName",
        },
        {
          text: "Created At",
          align: "center",
          sortable: false,
          value: "leadcreateAt",
        },
        {
          text: "Relationship",
          align: "center",
          sortable: false,
          value: "relation",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "actions",
        },
        // { text: "", value: "data-table-expand" },
      ],
    };
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getDuplicate();
      }
    },
    filterBy: {
      handler() {
        this.getDuplicate();
      },
    },

    expandedL(val) {
      if (val.length != 0) {
        this.getLeadData(val[0].uuid);
      }
    },
  },
  computed: {
    headers() {
      const h = [
        {
          text: "Fullname",
          align: "start",
          sortable: false,
          value: "fullname",
        },
        {
          text: "DOB",
          align: "center",
          sortable: false,
          value: "dob",
        },
        {
          text: "Counts",
          align: "center",
          sortable: false,
          value: "counts",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ];
      if (this.filterBy == "by_email") {
        return [
          {
            text: "Email",
            align: "start",
            sortable: false,
            value: "email",
          },
          {
            text: "Counts",
            align: "center",
            sortable: false,
            value: "email_count",
          },
          {
            text: "",
            align: "center",
            sortable: false,
            value: "actions",
          },
        ];
      }
      if (this.filterBy == "phone_number") {
        return [
          {
            text: "Phone",
            align: "start",
            sortable: false,
            value: "phone",
          },
          {
            text: "Counts",
            align: "center",
            sortable: false,
            value: "phone_count",
          },
          {
            text: "",
            align: "center",
            sortable: false,
            value: "actions",
          },
        ];
      }
      return h;
    },

    statusShow() {
      if (this.leadDetails == null) {
        return "-";
      }
      const est = this.leadDetails.statusNom
        ? this.leadDetails.statusNom.name
        : "No Status";
      if (est == null || est == undefined) {
        return "-";
      }
      switch (est) {
        case LeadStatus.NotInterested:
          return "Not Interested";
        case LeadStatus.WrongNumber:
          return "Wrong Number";
        default:
          return est;
      }
    },
    colorShow() {
      if (this.leadDetails == null) {
        return "#000";
      }
      return this.leadDetails.statusNom
        ? this.leadDetails.statusNom.color
        : "#000";
    },
    colorShowS() {
      if (this.leadDetails == null) {
        return "#000";
      }
      return this.leadDetails.sourceNom
        ? this.leadDetails.sourceNom.color
        : "#000";
    },
  },
  methods: {
    getLeadData(uuid) {
      this.loadingLead = true;
      getAPI(`/social/getLead/${uuid}`)
        .then((res) => {
          this.loadingLead = false;
          this.leadDetails = res.data;
        })
        .catch((err) => {
          this.loadingLead = false;
          let mess = err.response.data.message.toString();
          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    },

    getDuplicate() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 100;
      const range = {
        limit: itmper,
        offset: (page - 1) * itmper,
        filterByNameLastName: this.filterBy == "name_lastname" ? true : false,

        filterByPhone: this.filterBy == "phone_number" ? true : false,
        filterByEmail: this.filterBy == "by_email" ? true : false,
      };
      this.loading = true;
      getAPI
        .post("/reports/summary-duplicated-leads", range)
        .then((res) => {
          this.loading = false;
          this.duplicateLeads = res.data.result;
          this.total = res.data.count;
        })
        .catch((err) => {
          this.loading = false;
          let mess = err.response.data.message.toString();
          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    },

    showRelations(item) {
      let it = item;
      if (it.relations) {
        it.relations = it.relations.map((i, index) => {
          return { ...i, index };
        });
      }
      if (it.leads) {
        it.leads = it.leads.map((i, index) => {
          return { ...i, index };
        });
      }
      this.relationShow = { dialog: true, item: it };
    },
    closeShow() {
      this.expandedL = [];
      this.expandedR = [];
      this.leadDetails = null;
      this.relationShow = { dialog: false, item: null };
    },

    getDate(value) {
      if (value) {
        const val = value.split("T")[0];
        const y = val.split("-")[0];
        const m = val.split("-")[1];
        const d = val.split("-")[2];

        return `${m}/${d}/${y}`;
      }
      return "-";
    },

    getDOB(value) {
      if (value == null || value == undefined) {
        return "";
      }

      const x = moment(value).format("MM/DD/YYYY");

      return x;
    },
    getDOBR(value) {
      if (value == null || value == undefined) {
        return "";
      }

      const x = moment(value).format("MM/DD/YYYY HH:mm");

      return x;
    },
    gotoLead(lead) {
      this.$router.push({
        name: "Lead Details",
        params: { uuid: lead },
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
