<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);
Chart.defaults.set("plugins.datalabels", {
  color: "black",
  align: "center",
  anchor: "end",
  backgroundColor: "white",
  borderColor: function (context) {
    return context.dataset.backgroundColor;
  },
  borderRadius: 25,
  borderWidth: 2,
  color: "black",
  font: {
    weight: "bold",
  },
  padding: 6,
});
export default {
  data() {
    return {
      chart: null,
    };
  },
  props: ["chartData", "chartOptions"],
  watch: {
    chartData: {
      handler() {
        this.updateChart();
      },
      deep: true,
    },
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      const ctx = this.$refs.chartCanvas.getContext("2d");
      this.chart = new Chart(ctx, {
        type: "bar",
        data: this.chartData,
        options: {
          ...this.chartOptions,
        },
        plugins: [ChartDataLabels],
      });

      /*test*/
      /*  const ctx = this.$refs.chartCanvas.getContext("2d");

      this.chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["January", "February", "March", "April", "May"],
          datasets: [
            {
              label: "Sales",
              data: [10, 15, 999, 12, 2999],
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: [ChartDataLabels],
        },
      }); */

      /************** */
    },
    updateChart() {
      // Actualizar el gráfico cuando cambian los datos
      if (this.chart) {
        this.chart.data = this.chartData;
        this.chart.update();
      }
    },
  },
};
</script>

<style scoped>
/* Estilos personalizados para el componente si es necesario */
</style>
