<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card :disabled="loading" :loading="loading">
      <v-toolbar class="primary" tag="div" flat dark tile>
        <div class="text-h6">{{ "Status Changes" }}</div>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('closedialog')"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-toolbar
      >
      <template>
        <v-data-table
          :headers="headers"
          :items="statistics"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          :footer-props="footerProps"
          @click:row="showDetails"
          class="elevation-1"
        >
          <template v-slot:[`item.client`]="{ item }">
            <div>
              {{ item.client ? item.client.fullname : "-" }}
            </div>
          </template>
          <template v-slot:[`item.beforePolizeStatus`]="{ item }">
            <div
              class="text-body-1 font-weight-bold"
              v-bind:style="{ color: colorShow(item.beforePolizeStatus) }"
            >
              {{ statusShow(item.beforePolizeStatus) }}
            </div>
          </template>
          <template v-slot:[`item.polizeStatus`]="{ item }">
            <div
              class="text-body-1 font-weight-bold"
              v-bind:style="{ color: colorShow(item.polizeStatus) }"
            >
              {{ statusShow(item.polizeStatus) }}
            </div>
          </template>

          <template v-slot:[`item.gold`]="{ item }">
            <div class="font-weight-bold" v-bind:style="{ color: '#EDC43D' }">
              {{ item.gold }}
            </div>
          </template>
        </v-data-table>
      </template>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import { PolicyStatus } from "@/utils/enums/policystatus";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
export default {
  name: "statistic",
  props: {
    initialStatus: {
      type: String,
      default: () => null,
    },
    finalStatus: {
      type: String,
      default: () => null,
    },
    dateFrom: {
      type: String,
      default: () => null,
    },
    dateTo: {
      type: String,
      default: () => null,
    },
  },
  components: {
    MaDatePicker,
  },
  data() {
    return {
      statistics: [],
      loading: false,
      options: {},
      total: 0,
      footerProps: {
        "items-per-page-options": [100, 500, 1000],
      },

      headers: [
        {
          text: "Client",
          align: "start",
          sortable: false,
          value: "client",
        },
        {
          text: "Inital Status",
          align: "center",
          sortable: false,
          value: "beforePolizeStatus",
        },
        {
          text: "Final Status",
          align: "center",
          sortable: false,
          value: "polizeStatus",
        },
      ],

      workers: [],
      clients: [],
      loadingPatient: false,
      req: {
        offset: 0,
        limit: 100,

        interval: {
          date1: "",
          date2: "",
        },
        initialStatus: "",
        finalStatus: "",
      },
      states: [
        { value: "POLIZE_PENDING", label: "Policy Pending" },
        { value: "POLIZE_OK", label: "Policy Ok" },
        {
          value: "POLIZE_MEMBERS_NO_MATCH",
          label: "Policy Member does not match",
        },
        {
          value: "PAYMENT_MEMBERS_NO_MATCH",
          label: "Member payment does not match",
        },
        { value: "PAYMENT_OK", label: "Payment Ok" },
        { value: "PAYMENT_PENDING", label: "Payment Pending" },
        { value: "POLICY_CANCELLATION", label: "Policy Cancellation" },
        { value: "POLICY_RENEWAL", label: "Policy Renewal" },
        { value: "POLICY_RECONCILIATION", label: "Policy Reconciliation" },
        { value: "OTHER_PARTY", label: "Other Party" },
        { value: "NO_AOR", label: "No Aor" },
      ],
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["loadingRefer", "companies"]),
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getStatistics();
      }
    },
    dateFrom(val) {
      if (val != null && this.dateTo != null && this.dateTo != "") {
        this.getStatistics();
      }
    },
    dateTo(val) {
      if (val != null && this.dateFrom != null && this.dateFrom != "") {
        this.getStatistics();
      }
    },
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actListCompaniesNom"]),
    getStatistics() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;

      this.loading = true;
      let body = Object.assign({}, this.req);
      body.limit = itmper;
      body.offset = (page - 1) * itmper;
      body.finalStatus = this.finalStatus;
      body.initialStatus = this.initialStatus;
      body = this.cleanNull(body);

      if (!this.dateFrom || !this.dateTo) {
        delete body.interval;
      } else {
        body.interval = {
          date1: this.dateFrom,
          date2: this.dateTo,
        };
      }
      getAPI
        .post("/reports/filter-status-changes", body)
        .then((res) => {
          this.statistics = res.data.result;
          this.total = res.data.count;
          this.loading = false;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    colorShow(item) {
      const status = item;
      if (status != null && status != undefined) {
        switch (status) {
          case "POLIZE_PENDING":
            return "#1663E0FF";
          case "PAYMENT_PENDING":
            return "#4B7ECE";
          case "POLIZE_OK":
            return "#19FF00FF";
          case "PAYMENT_OK":
            return "#00FFB3";
          case "POLIZE_MEMBERS_NO_MATCH":
            return "#FF0033";
          case "PAYMENT_MEMBERS_NO_MATCH":
            return "#FFAA00";
          case "POLICY_CANCELLATION":
            return "#FF4400";
          case "POLICY_RECONCILIATION":
            return "#B7FF00";
          case "POLICY_RENEWAL":
            return "#EEC167";
          case "OTHER_PARTY":
            return "#9C27B0";
          case "NO_AOR":
            return "#625F62";

          default:
            return "#000";
        }
      }
      return "#000";
    },

    statusShow(item) {
      if (item) {
        const st = PolicyStatus[item];
        return st;
      }

      return "-";
    },
    showDetails(item) {
      this.$router.push(`/clients/details/${item.client.uuid}`);
    },
    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
};
</script>
<style lang=""></style>
