<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card :disabled="loading" :loading="loading">
      <v-container>
        <v-layout row wrap class="mx-1">
          <v-switch
            label="By Creator"
            inset
            v-model="byCreator"
            @change="getStatistics"
          ></v-switch>
        </v-layout>
        <template>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="statistics"
            :footer-props="footerProps"
            class="elevation-1"
          >
            <template v-slot:[`item.policies`]="{ item }">
              <div class="cursor-pointer" @click="showDetails(item)">
                {{ item.policies }}
              </div>
            </template>
            <template v-slot:[`item.bronze`]="{ item }">
              <div class="font-weight-bold" v-bind:style="{ color: '#CD7F32' }">
                {{ item.bronze }}
              </div>
            </template>
            <template v-slot:[`item.silver`]="{ item }">
              <div class="font-weight-bold" v-bind:style="{ color: '#C0C0C0' }">
                {{ item.silver }}
              </div>
            </template>
            <template v-slot:[`item.gold`]="{ item }">
              <div class="font-weight-bold" v-bind:style="{ color: '#EDC43D' }">
                {{ item.gold }}
              </div>
            </template>
          </v-data-table>
          <v-dialog
            v-model="dialog"
            scrollable
            fullscreen
            persistent
            :overlay="true"
            transition="dialog-transition"
          >
            <template v-if="dialog && currentItem">
              <policies-view
                :typeData="type"
                :idSearch="currentItem"
                :nameItem="nameItem"
                @closeDialog="closeDialog"
              />
            </template>
          </v-dialog>
        </template>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import PoliciesView from "@/views/Statistics/PoliciesView.vue";
export default {
  name: "statistic-employee",
  components: { PoliciesView },
  data() {
    return {
      byCreator: false,
      statistics: [],
      loading: false,
      dialog: false,
      currentItem: null,
      type: "agent",
      nameItem: null,
      footerProps: {
        "items-per-page-options": [100, 500, 1000],
      },
      headers: [
        {
          text: "Employee",
          align: "start",
          sortable: true,
          value: "fullname",
        },
        {
          text: "Policies",
          align: "center",
          sortable: true,
          value: "policies",
        },
        {
          text: "Active Policies",
          align: "center",
          sortable: true,
          value: "active_polices",
        },
        {
          text: "Members",
          align: "center",
          sortable: true,
          value: "members",
        },
        {
          text: "Bronze",
          align: "center",
          sortable: true,
          value: "bronze",
        },
        {
          text: "Silver",
          align: "center",
          sortable: true,
          value: "silver",
        },
        {
          text: "Gold",
          align: "center",
          sortable: true,
          value: "gold",
        },
      ],
    };
  },
  methods: {
    getStatistics() {
      this.loading = true;
      let url = "/reports/policies-by-employee";
      if (this.byCreator) {
        url = "/reports/policies-by-employee?byCreator=true";
      }
      getAPI(url)
        .then((res) => {
          this.statistics = res.data;
          this.loading = false;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    showDetails(item) {
      this.dialog = true;
      this.type = this.byCreator ? "creator" : "agent";

      this.currentItem = item.remployee_uuid ? item.remployee_uuid : "total";
      this.nameItem = item.remployee_uuid ? item.fullname : "All Policies";
    },
    closeDialog() {
      this.dialog = false;
      this.currentItem = null;
      this.nameItem = null;
    },
  },
  mounted() {
    this.getStatistics();
  },
};
</script>
<style lang=""></style>
