<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card :disabled="loading" :loading="loading">
      <v-row no-gutters justify="end" class="px-4 py-2">
        <v-col cols="12" sm="2" class="px-1">
          <ma-date-picker v-model="dateFrom" label="From" past />
        </v-col>
        <v-col cols="12" sm="2">
          <ma-date-picker v-model="dateTo" label="To" past />
        </v-col>

        <v-col cols="12" sm="1">
          <template v-if="validClean">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ml-2"
                  elevation="0"
                  small
                  rounded
                  depressed
                  fab
                  v-on="on"
                  v-bind="attrs"
                  @click="_clearFilters"
                >
                  <v-icon>mdi-backup-restore</v-icon>
                </v-btn>
              </template>
              <span>Clear Date filters</span>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
      <template>
        <v-row class="pa-2" no-gutters>
          <v-col cols="12" sm="12">
            <!--  <div class="d-flex justify-end">
              <template v-if="!viewGraph">
                <v-btn color="success" text @click="configureGraph"
                  ><v-icon>mdi-chart-line</v-icon>Graph</v-btn
                >
              </template>
              <template v-else>
                <v-btn color="error" text @click="closeGraph"
                  ><v-icon>mdi-chart-line</v-icon>Close</v-btn
                >
              </template>
            </div> -->
            <div>
              <v-data-table
                :headers="headers"
                :items="statistics"
                :options.sync="options"
                :loading="loading"
                :footer-props="footerProps"
                @click:row="showDetails"
                class="elevation-1"
              >
                <template v-slot:[`item.name`]="{ item }">
                  <div
                    class="text-body-1 cursor-pointer font-weight-bold"
                    v-bind:style="{ color: colorSourceEye(item) }"
                  >
                    {{ item.name }}
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-col>
          <!--   <template v-if="viewGraph">
            <v-col cols="12" sm="12" class="px-4 pt-4">
              <base-graphic :configC="configGraph" />
            </v-col>
          </template> -->
        </v-row>
      </template>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import { mapActions, mapState } from "vuex";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import moment from "moment";
import BaseGraphic from "@/components/graphics/BaseGraphic.vue";
export default {
  name: "statistic-converted",

  components: {
    MaDatePicker,
    BaseGraphic,
  },
  data() {
    return {
      dateTo: "",
      dateFrom: "",
      statistics: [],
      loading: false,
      viewGraph: false,
      configGraph: null,
      options: {},
      total: 0,
      footerProps: {
        "items-per-page-options": [100, 500, 1000],
      },

      headers: [
        {
          text: "Source",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Converted",
          align: "center",
          sortable: false,
          value: "converted",
        },
        /* {
          text: "Clients",
          align: "center",
          sortable: false,
          value: "clients",
        }, */
      ],

      req: {
        /*  offset: 0,
        limit: 100, */

        interval: {
          date1: "",
          date2: "",
        },
      },
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["loadingRefer", "references"]),
    validClean() {
      if (
        (this.dateFrom != null &&
          this.dateFrom != undefined &&
          this.dateFrom != "") ||
        (this.dateTo != null && this.dateTo != undefined && this.dateTo != "")
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getStatistics();
      }
    },
    dateFrom(val) {
      if (val != null && this.dateTo != null && this.dateTo != "") {
        this.getStatistics();
      }
    },
    dateTo(val) {
      if (val != null && this.dateFrom != null && this.dateFrom != "") {
        this.getStatistics();
      }
    },
  },
  mounted() {
    this.actListReferences();
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actListReferences"]),
    colorSourceEye(data) {
      if (this.references.length != 0) {
        const x = this.references.find((r) => r.name == data.name);
        return x ? x.color : "#000";
      }
      return "#000";
    },
    getStatistics() {
      /* const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10; */

      this.loading = true;

      /*   body.limit = itmper;
      body.offset = (page - 1) * itmper; */

      let body = {
        interval: {
          date1: "",
          date2: "",
        },
      };

      if (this.dateFrom == "" || this.dateTo == "") {
        delete body.interval;
      } else {
        body.interval = {
          date1: moment(this.dateFrom).startOf("day").toISOString(),
          date2: moment(this.dateTo).endOf("day").toISOString(),
        };
      }
      getAPI
        .post("/reports/sumary-converted-by-source", body)
        .then((res) => {
          this.statistics = res.data;
          this.viewGraph = false;
          this.loading = false;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    _clearFilters() {
      if (this.dateFrom != "" && this.dateTo != "") {
        this.dateFrom = "";
        this.dateTo = "";
        this.getStatistics();
      } else {
        this.dateFrom = "";
        this.dateTo = "";
      }
    },

    configureGraph() {
      const labels = this.statistics.map((s) => {
        return s.name;
      });
      const leads = this.statistics.map((s) => {
        return s.leads;
      });
      const clients = this.statistics.map((s) => {
        return s.clients;
      });

      const data = {
        labels: labels,
        datasets: [
          {
            label: "Clients",
            data: clients,

            backgroundColor: ["rgba(75, 192, 192)"],

            borderRadius: 3,
            borderSkipped: false,
          },
          {
            label: "Leads",
            data: leads,

            backgroundColor: ["rgba(54, 162, 235)"],

            borderRadius: 3,
            borderSkipped: false,
          },
        ],
      };
      this.configGraph = {
        type: "bar",
        data: data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            /*  title: {
              display: true,
              text: "Chart.js Bar Chart",
            }, */
          },
        },
      };
      this.viewGraph = true;
    },
    closeGraph() {
      this.viewGraph = false;
    },

    showDetails(item) {
      this.$router.push(`/clients/details/${item.client.uuid}`);
    },
    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
};
</script>
<style lang=""></style>
